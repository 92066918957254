@import 'styles/includes';

.MyPagesLoginChoices {
    $root: &;

    padding: 4rem 0 36rem 0;
    background: $colorGradient;

    &__Container {
        @extend %container;
    }

    &__Message {
        margin-bottom: 2.4rem;
        color: $colorError;
    }

    &__Title {
        @extend %h3;
        margin: 0 0 3.2rem;
        color: $colorBlue;
    }

    &__Grid {
        display: grid;
        gap: 2.6rem;

        @include media(m) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
